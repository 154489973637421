/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import clsx from "classnames";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { useLocation } from "react-router";
import DetailsBlock from "components/General/SectionDetails/DetailsBlock";
import {
  determineSourceAccount,
  determineTransactionStatus,
  determineTransactionType,
  determineTxnFrom,
  transactionAmount,
} from "utils/transactions";
import { numberWithCommas } from "utils/formatter";
import useDefaultCurrency from "hooks/defaultCurrency";
import CircleLoader from "components/General/CircleLoader/CircleLoader";
import { HiOutlineReceiptRefund } from "react-icons/hi";
import { RiRefundLine } from "react-icons/ri";
import transactionsSlice from "../../../slice";
import walletSlice from "pages/Dashboard/Wallet/slice";
import { TransactionDetailsWrapper } from "../../../style";
import useLocationParams from "hooks/useLocationParams";
import { Confirmation } from "components/Wallet/Withdrawal";
import { Button } from "components/General/Button";
import useWallet from "hooks/wallet";
import { NAIRA_ABBR, walletConstants } from "utils/appConstant";
import { successToast } from "components/General/Toast/Toast";
import { useAuth } from "hooks/auth";
import { getCountryCodeFromCurrency, handleToCurrencyValue } from "utils/data";
import { RefundCustomer } from "./RefundCustomer";
const { BANK_TRANSFER, MOBILE_MONEY } = walletConstants;
export default function TransactionDetails({
  handleCloseModal,
  clearFilters,
  getTransaction,
}) {
  const { transactionDetails, transactionDetailsLoading } = useSelector(
    (state) => state.transactions
  );
  const transactionType = BANK_TRANSFER;
  const IS_BANK_TRANSFER = transactionType === BANK_TRANSFER;
  const IS_MOBILE_MONEY = transactionType === MOBILE_MONEY;
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const transaction_reference = searchParams.get("transaction_reference");
  const {
    transferring,
    fiatWallets,
    cryptoWallets,
    defaultFiatCurrency,
    refunding,
    resendWebhookLoading,
  } = useWallet();

  const { handleAddParam, handleGetParam, handleRemoveParam } =
    useLocationParams({});
  const payout_txn_ref = handleGetParam("payout_txn_ref");

  const refund_txn_ref = handleGetParam("refund_txn_ref");
  const resend_webhook_txn_ref = handleGetParam("resend_webhook_txn_ref");

  const { actions } = transactionsSlice;
  const { actions: walletActions } = walletSlice;
  const dispatch = useDispatch();
  const { currency } = useDefaultCurrency();
  const { merchantDetails } = useSelector((state) => state.marchants);
  const { tribe_account_id } = useAuth();
  const [transferDetails, setTransferDetails] = useState({
    save_as_beneficiary: false,
  });

  const account_tribe_ref = merchantDetails?.account_tribe_ref;

  useEffect(() => {
    if (transaction_reference && merchantDetails?.account_tribe_ref) {
      dispatch(
        actions.getTransactionDetails({
          transaction_reference: transaction_reference,
          tribeRef: merchantDetails?.account_tribe_ref,
        })
      );
    }
    return () => {
      dispatch(actions.resetTransactionDetails());
    };
  }, [transaction_reference, merchantDetails?.account_tribe_ref]);

  useEffect(() => {
    handleRemoveParam("payout_txn_ref");
    handleRemoveParam("refund_txn_ref");
    handleRemoveParam("resend_webhook_txn_ref");
  }, [transaction_reference, transactionDetailsLoading]);

  const txnStatus =
    transactionDetails?.payout_details?.payout_status ||
    transactionDetails?.payin_details?.pay_status ||
    transactionDetails?.fiat_payin_details?.pay_status ||
    transactionDetails?.fiat_payout_details?.payout_status ||
    transactionDetails?.crypto_payin_details?.transaction_status ||
    transactionDetails?.crypto_payout_details?.transfer_status ||
    transactionDetails?.exchange_details?.exchange_status ||
    "";

  const payoutTxnStatus =
    transactionDetails?.payout_details?.payout_status ||
    transactionDetails?.fiat_payout_details?.payout_status ||
    transactionDetails?.crypto_payout_details?.transaction_status ||
    "";
  const transactionStatus = determineTransactionStatus(
    txnStatus,
    payoutTxnStatus
  );

  const paymentMethod = transactionDetails?.exchange_details
    ? transactionDetails.exchange_details.exchange_type
    : transactionDetails?.payin_details?.pay_method.replace("_", " ") ||
      transactionDetails?.payout_details?.payout_method.replace("_", " ") ||
      (transactionDetails?.crypto_payin_details && "Crypto") ||
      (transactionDetails?.crypto_payout_details && "Crypto") ||
      "";
  const transactionSummary = {
    "Transaction reference":
      transactionDetails?.crypto_payin_details?.payment_ref ||
      transactionDetails?.crypto_payout_details?.payment_ref ||
      transactionDetails?.transaction_reference,
    "Transaction note": transactionDetails?.transaction_note,
    payment_method:
      paymentMethod?.includes?.("wallet") &&
      transactionDetails?.tx_indicator?.includes("bani")
        ? "Bani Shopper"
        : paymentMethod,
    "Session ID":
      transactionDetails?.payout_details?.remit_ext_tag ||
      transactionDetails?.payin_details?.remit_ext_tag,
    From: determineTxnFrom(transactionDetails),
    "Date/Time":
      transactionDetails?.pub_date &&
      moment(transactionDetails?.pub_date).format("DD MMM, YYYY h:mmA"),
    "Coin amount collected":
      determineTransactionStatus(
        txnStatus,

        payoutTxnStatus
      ) === "part paid"
        ? transactionDetails?.crypto_payin_details?.coin_amount_collected ||
          transactionDetails?.crypto_payout_details?.coin_amount_collected
        : "",
    Status: transactionStatus,
    "Order total amount":
      determineTransactionStatus(txnStatus, payoutTxnStatus) === "part paid"
        ? transactionDetails?.payin_details?.merch_amount &&
          transactionDetails?.payin_details?.merch_currency +
            " " +
            transactionDetails?.payin_details?.merch_amount
        : "",

    "Balance after":
      transactionDetails?.balance_fiat_after > 0
        ? `${
            currency ||
            transactionDetails?.payin_details?.merch_currency ||
            transactionDetails?.fiat_payin_details?.merch_currency ||
            transactionDetails?.payout_details?.receiver_currency
          } ${numberWithCommas(transactionDetails?.balance_fiat_after)}`
        : transactionDetails?.balance_coin_after,
    account_name: determineSourceAccount(transactionDetails),
    account_num:
      transactionDetails?.payout_details?.receiver_account_num ||
      transactionDetails?.payout_details?.receiver_phone ||
      transactionDetails?.payin_details?.holder_account_number ||
      transactionDetails?.payin_details?.holder_phone ||
      "",
    "Fiat amount": transactionDetails?.crypto_payin_details?.merch_amount
      ? transactionDetails?.crypto_payin_details?.merch_currency +
        transactionDetails?.crypto_payin_details?.merch_amount
      : transactionDetails?.crypto_payout_details?.merch_amount
      ? transactionDetails?.crypto_payout_details?.merch_currency +
        transactionDetails?.crypto_payout_details?.merch_amount
      : "",
    bank_name_phone_carrier:
      transactionDetails?.payout_details?.receiver_bank_name ||
      transactionDetails?.payin_details?.holder_bank_name ||
      transactionDetails?.payin_details?.holder_phone_carrier ||
      "",
    "Transaction hash": transactionDetails?.ex_hash,

    // "Order reference": (
    //   <span className="highlight-text">28289127182162718</span>
    // ),
  };

  const payout_details = transactionDetails?.payout_details;
  const payin_details = transactionDetails?.payin_details;
  const carryOutTransfer = () => {
    const from = defaultFiatCurrency;
    const to = defaultFiatCurrency;

    const {
      payout_details: {
        receiver_sort_code,
        receiver_amount,
        receiver_account_num,
        receiver_bank_name,
        receiver_account_name,
      },
    } = transactionDetails;

    dispatch(
      walletActions.startTransfer({
        payload: {
          tribe_account_id,
          tribeRef: account_tribe_ref,
          payout_step: "direct",
          transfer_method: IS_BANK_TRANSFER
            ? "bank"
            : IS_MOBILE_MONEY
            ? "mobile_money"
            : "",
          receiver_country_code: IS_MOBILE_MONEY
            ? transferDetails?.country
            : getCountryCodeFromCurrency(to),
          receiver_currency: handleToCurrencyValue(to),
          receiver_amount: IS_BANK_TRANSFER
            ? receiver_amount
            : transferDetails.to_amount,
          sender_amount: receiver_amount || transferDetails.amount,
          transfer_receiver_type: "personal",
          receiver_sort_code,
          receiver_account_num,
          is_beneficiary: !!transferDetails.save_as_beneficiary,
          receiver_bank_name,
          receiver_account_name,
          sender_wallet_ref:
            fiatWallets.find((wallet) => wallet.currency === from)
              ?.wallet_account_ref ||
            cryptoWallets.find((wallet) => wallet.crypto_wallet_type === from)
              ?.wallet_crypto_ref,
          ...(transferDetails?.transfer_note && {
            transfer_note: transferDetails?.transfer_note,
          }),
        },
        onSuccess: () => {
          successToast(
            "Payout processed!",
            "Payout has been processed succesfully!"
          );
          handleRemoveParam("payout_txn_ref");
          handleCloseModal?.();
          clearFilters?.();
        },
      })
    );
  };

  const refundCustomer = () => {
    dispatch(
      walletActions.startRefund({
        payload: {
          tribeRef: merchantDetails?.account_tribe_ref,
          transaction_reference: refund_txn_ref,
        },

        onSuccess: () => {
          successToast(
            "Refund processed!",
            "Refund has been processed succesfully!"
          );
          handleRemoveParam("refund_txn_ref");
          handleCloseModal?.();
          clearFilters?.();
          getTransaction?.();
        },
      })
    );
  };

  const resendWebhook = () => {
    dispatch(
      walletActions.resendWebhook({
        payload: {
          tribeRef: merchantDetails?.account_tribe_ref,
          tx_ref: resend_webhook_txn_ref,
          resend: true,
        },

        onSuccess: () => {
          successToast(
            "Success!",
            "Webhook notification has been sent succesfully!"
          );
          handleRemoveParam("resend_webhook_txn_ref");
          handleCloseModal?.();
          clearFilters?.();
          getTransaction?.();
        },
      })
    );
  };
  const isBaniShopperInflow =
    transactionDetails?.tx_indicator?.includes("bani") &&
    paymentMethod?.includes?.("wallet") &&
    !transactionDetails?.transaction_note?.includes("Refund Charge") &&
    determineTransactionType(transactionDetails, true) === "Credit";
  return (
    <div className="h-full">
      <div className="flex flex-grow justify-center items-center h-full w-full pt-4 sm:pt-0">
        {transactionDetailsLoading ? (
          <div className="flex flex-grow justify-center items-center h-full w-full min-h-[60vh] pt-6 sm:pt-0">
            <CircleLoader blue />
          </div>
        ) : payout_txn_ref ? (
          <div className="flex flex-col">
            <Confirmation
              setTransferDetails={setTransferDetails}
              transferDetails={{
                ...transferDetails,
                ...transactionDetails,
                from: NAIRA_ABBR,
                pageType: "transaction_details",
              }}
              transactionType={transactionType}
            />

            <div className="w-full flex justify-between gap-6 px-6 mt-4 mb-[40px]">
              <Button
                text="Cancel"
                isDisabled={transferring}
                onClick={() => handleRemoveParam("payout_txn_ref")}
                textColor="text-black"
                whiteBg
                fullWidth
              />
              <Button
                text="Confirm"
                isLoading={transferring}
                onClick={carryOutTransfer}
                fullWidth
              />
            </div>
          </div>
        ) : refund_txn_ref ? (
          <div className="flex flex-col">
            <RefundCustomer
              setTransferDetails={setTransferDetails}
              transferDetails={{
                to: NAIRA_ABBR,
                amount: transactionAmount(transactionDetails, true),
                account_name: determineSourceAccount(transactionDetails),
              }}
            />

            <div className="w-full flex justify-between gap-6 px-6 mt-4 mb-[40px]">
              <Button
                text="Cancel"
                isDisabled={refunding}
                onClick={() => handleRemoveParam("refund_txn_ref")}
                textColor="text-black"
                whiteBg
                fullWidth
              />
              <Button
                text="Confirm"
                isLoading={refunding}
                onClick={refundCustomer}
                fullWidth
              />
            </div>
          </div>
        ) : resend_webhook_txn_ref ? (
          <div className="flex flex-col">
            <div className="py-8 px-6 flex flex-col justify-center items-center w-full text-grey-text text-center">
              You&apos;re about to resend webhook notification for this
              transaction. Hit confirm below to proceed.
            </div>

            <div className="w-full flex justify-between gap-6 px-6 mt-4 mb-[40px]">
              <Button
                text="Cancel"
                isDisabled={resendWebhookLoading}
                onClick={() => handleRemoveParam("resend_webhook_txn_ref")}
                textColor="text-black"
                whiteBg
                fullWidth
              />
              <Button
                text="Confirm"
                isLoading={resendWebhookLoading}
                onClick={resendWebhook}
                fullWidth
              />
            </div>
          </div>
        ) : (
          <TransactionDetailsWrapper className="transaction-details ">
            <div className="transaction-main-content mt-1 sm:mt-0">
              <div className="flex justify-between items-center gap-2 py-2 px-5 border-b-1/2 border-grey-border mb-6">
                <div className="flex flex-col justify-start items-start gap-2">
                  <p className="text-sm text-grey-text">
                    {transactionAmount(transactionDetails) && "Amount"}
                  </p>
                  <div className="flex justify-start items-center gap-8 w-full">
                    <span className="flex justify-start uppercase basier-medium text-xl max-w-[70%]">
                      {transactionAmount(transactionDetails)}
                    </span>

                    <div
                      className={clsx(
                        "px-2.5  rounded justify-center items-center gap-4 flex",
                        {
                          "bg-green-100":
                            determineTransactionType(
                              transactionDetails,
                              true
                            ) === "Credit",
                          "bg-[#ffebe6]":
                            determineTransactionType(
                              transactionDetails,
                              true
                            ) === "Debit",
                        }
                      )}
                    >
                      <div className="justify-start items-center gap-1.5 flex">
                        <div
                          className={clsx(
                            "text-sm font-normal leading-tight tracking-tight capitalize py-0.5",
                            {
                              "text-green-light":
                                determineTransactionType(
                                  transactionDetails,
                                  true
                                ) === "Credit",
                              "text-red":
                                determineTransactionType(
                                  transactionDetails,
                                  true
                                ) === "Debit",
                            }
                          )}
                        >
                          {determineTransactionType(transactionDetails, true)}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {payout_details &&
                (transactionStatus === "failed" ||
                  transactionStatus === "completed") &&
                !transactionDetails?.transaction_note?.includes(
                  "Remittance Charge"
                ) ? (
                  <div
                    onClick={() => {
                      handleAddParam(
                        "payout_txn_ref",
                        transactionDetails?.transaction_reference
                      );
                    }}
                    className="text-blue text-sm font-medium px-3 h-8 text-center cursor-pointer bg-violet-100 rounded-md border border-blue justify-center items-center gap-2 flex whitespace-nowrap"
                  >
                    {transactionStatus === "completed" ? "Resend" : "Retry"}
                    <HiOutlineReceiptRefund size={18} />
                  </div>
                ) : payin_details && isBaniShopperInflow ? (
                  <div
                    onClick={() => {
                      handleAddParam(
                        "refund_txn_ref",
                        transactionDetails?.transaction_reference
                      );
                    }}
                    className="text-blue text-sm font-medium px-3 h-8 text-center cursor-pointer bg-violet-100 rounded-md border border-blue justify-center items-center gap-2 flex whitespace-nowrap"
                  >
                    Refund
                    <RiRefundLine size={18} />
                  </div>
                ) : null}
              </div>

              <div className="transaction-summary px-[20px]">
                {Object.keys(transactionSummary).map((item) => (
                  <DetailsBlock
                    title={item}
                    value={transactionSummary[item]}
                    key={item}
                    transactionDetails={transactionDetails}
                  />
                ))}

                {transactionStatus === "completed" ||
                transactionStatus === "part paid" ? (
                  <div
                    onClick={() => {
                      handleAddParam(
                        "resend_webhook_txn_ref",
                        transactionDetails?.transaction_reference
                      );
                    }}
                    className="text-blue text-sm font-medium px-3 h-[44px] text-center cursor-pointer bg-violet-100 rounded-md border border-blue justify-center items-center gap-2 flex whitespace-nowrap w-full"
                  >
                    Resend webhook
                    <HiOutlineReceiptRefund size={18} />
                  </div>
                ) : null}
              </div>
            </div>
          </TransactionDetailsWrapper>
        )}
      </div>
    </div>
  );
}
